import { useShoppingCartStore } from "@multicines/stores";
import { CinemaStores, City } from "@multicines/types";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { Button, Modal } from "artisn-ui-react";
import ArrowToDown from "images/arrow-left-small.svg";
import CloseSVG from "images/close.svg";
import React, { useState } from "react";

import Styles from "./ChangeStore.styles";
import { ChangeStoreProps as Props } from "./ChangeStore.types";
import { ModalCinema } from "components/CinemaAndDay/CinemaAndDay.styles";
import ChangeStoreModal from "components/global/ChangeStoreModal/ChangeStoreModal";
import SelectCinema from "components/global/SelectCinema/SelectCinema";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";
import { useStoresStore } from "stores/stores/stores.store";

const ChangeStore: React.FC<Props> = props => {
  const { redirect, buttonStyle, textButton, textStyle } = props;
  const { showIcon = false } = props;
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const t = useI18n();
  const currentStore = useStoresStore(store => store.selectedStore);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const [showSelectModal, setSelectShowModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City | undefined>();
  const [selectedStore, setSelectedStore] = useState<
    CinemaStores | undefined
  >();

  const { logSelectLocationCinema } = analyticsHook;

  const [ticketStore] = currentStore ?? [];
  const { storeName } = ticketStore ?? {};

  const products = shoppingCart
    ? getShoppingCartProducts(shoppingCart)
    : undefined;
  const empty = (products && products.length === 0) || !shoppingCart;

  const changeModalHandler = () => {
    logSelectLocationCinema();

    if (empty) {
      setSelectShowModal(true);
      setShowChangeModal(false);
      return;
    }

    setShowChangeModal(false);
    setSelectShowModal(true);
  };

  const handleChangeCinema = (newCity: City, newStore: CinemaStores) => {
    setSelectedCity(newCity);
    setSelectedStore(newStore);
    setShowChangeModal(true);
  };

  const textChange =
    redirect || !storeName ? t.navigation.Navbar.selectCinema : storeName;
  const textBtn = textButton ?? textChange;

  return (
    <>
      <Styles className="ChangeStore">
        <Button
          className={`ChangeStore__store ${buttonStyle}`}
          onClick={changeModalHandler}
        >
          <p className={`ChangeStore__text ${textStyle}`}>{textBtn}</p>
          {showIcon ? <ArrowToDown className="ChangeStore__icon" /> : null}
        </Button>
      </Styles>
      <ModalCinema
        color="hsla(223, 13%, 11%, 1)"
        closeIcon={<CloseSVG />}
        opened={showSelectModal}
        onClose={() => {
          setSelectShowModal(false);
        }}
      >
        <SelectCinema
          onChangeCinema={handleChangeCinema}
          setSelectShowModal={setSelectShowModal}
          setShowModal={setSelectShowModal}
        />
      </ModalCinema>
      {selectedCity && selectedStore ? (
        <Modal
          color="transparent"
          closeIcon={<CloseSVG />}
          opened={showChangeModal}
          onClose={() => setShowChangeModal(false)}
        >
          <ChangeStoreModal
            setShowSelectModal={setSelectShowModal}
            setShowChangeModal={setShowChangeModal}
            newCity={selectedCity}
            newStore={selectedStore}
            redirect={redirect}
          />
        </Modal>
      ) : null}
    </>
  );
};

ChangeStore.defaultProps = {};

export default ChangeStore;

import { Button, Modal } from "artisn-ui-react";
import Lottie from "lottie-react-web";
import React from "react";

import { backdropConfig } from "./HighTrafficModal.helpers";
import Styles from "./HighTrafficModal.styles";
import { HighTrafficModalProps as Props } from "./HighTrafficModal.types";
import useI18n from "hooks/useI18n";

import LoadingHighTrafficAnimation from "../../../../public/assets/animations/loadingHighTraffic.json";
import StarHighTrafficAnimation from "../../../../public/assets/animations/starHighTraffic.json";

const HighTrafficModal: React.FC<Props> = props => {
  const { trafficModalState, onPress } = props;
  const t = useI18n();
  const animation =
    trafficModalState === 1
      ? LoadingHighTrafficAnimation
      : StarHighTrafficAnimation;

  return (
    <Modal
      backdropConfig={backdropConfig}
      className="HighTrafficModal__modal"
      opened={true}
      width={500}
      height={500}
      color="transparent"
    >
      <Styles className="HighTrafficModal">
        <div className="HighTrafficModal__svg">
          <Lottie
            options={{
              animationData: animation,
              autoplay: true,
              loop: true
            }}
          />
        </div>
        <h1 className="HighTrafficModal__title">
          {trafficModalState === 1
            ? t.highTraffic.title1
            : t.highTraffic.title2}
        </h1>
        <p className="HighTrafficModal__description">
          {trafficModalState === 1
            ? t.highTraffic.content1
            : t.highTraffic.content2}
        </p>
        {trafficModalState === 2 ? (
          <Button className="HighTrafficModal__button" onClick={onPress}>
            {t.highTraffic.buttonContent}
          </Button>
        ) : null}
      </Styles>
    </Modal>
  );
};

export default HighTrafficModal;
